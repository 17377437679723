import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import ThemeToggleComponent from './ThemeToggleComponent';

const HeaderComponent = () => (
  // <header className="bg-gray-800 dark:bg-gray-100 text-white p-4 fixed lg:w-3/4 w-full top-0 z-50 transition-all duration-300">
  <header className="bg-header dark:bg-darkheader text-white p-4 fixed w-full top-0 z-50 transition-all duration-300">
    <div className="container mx-auto flex justify-between items-center">
      <h1 className="text-2xl font-bold text-text dark:text-darktext transition-all duration-300">Kasra Babazadeh</h1>
      <nav className="flex items-center">
        <ThemeToggleComponent />
      </nav>
    </div>
  </header>
);

export default HeaderComponent;
