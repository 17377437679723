import React, { useState } from 'react';
import { FaTelegramPlane, FaInstagram, FaGithub } from 'react-icons/fa';
import { TbBrandLinkedin } from "react-icons/tb";
import { CiLocationOn, CiPhone, CiMail, CiLinkedin } from 'react-icons/ci';

const ContactComponent = () => {
  const [formData, setFormData] = useState({
    forename: '',
    surname: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://kasrababazadeh.com/api/contact/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        // Optionally reset the form
        setFormData({
          forename: '',
          surname: '',
          email: '',
          message: '',
        });
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <section id="contact" className="min-h-screen px-10 bg-main dark:bg-darkmain transition-all duration-300 py-24">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold mb-4 text-text dark:text-darktext transition-all duration-300 text-center">Contact Me</h2>
        <p className="text-lg text-text dark:text-darktext mb-4 text-center transition-all duration-300">Feel free to reach out via email or follow me on social media!</p>
        <br />
        <br />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 max-w-screen-lg mx-auto">
          {/* Information Grid */}
          <div>
            <h3 className="text-2xl font-bold mb-4 text-text dark:text-darktext">Get In Touch</h3>
            <p className="text-md text-text dark:text-darktext mb-4">
              I'm here to answer any questions or discuss potential opportunities.
            </p>
            <div className="flex flex-col gap-4 mb-4 text-text dark:text-darktext">
              <div className="flex flex-row gap-4 items-center" aria-label="Address">
                <CiLocationOn className='bg-darkicon text-darktext p-2.5 rounded-full' size={50} />
                <div>
                <h3 className='text-xl'><strong>Address</strong></h3>
                <h3>Tehran, Tehran Province, Iran</h3>
                </div>
              </div>
              <div className="flex flex-row gap-4 items-center" aria-label="PhoneNumber">
                <CiPhone className='bg-darkicon text-darktext p-2.5 rounded-full' size={50} />
                <div>
                <h3 className='text-xl'><strong>Phone Number</strong></h3>
                <h3>+98-937-428-3010</h3>
                </div>
              </div>
              <div className="flex flex-row gap-4 items-center" aria-label="EMail">
                <CiMail className='bg-darkicon text-darktext p-2.5 rounded-full' size={50} />
                <div>
                <h3 className='text-xl'><strong>E-Mail</strong></h3>
                <h3><a href="mailto:kasra.babazadeh@gmail.com" className="text-sky-400 dark:text-sky-400 hover:font-semibold underline transition-all duration-300">kasra.babazadeh@gmail.com</a></h3>
                </div>
              </div>
            </div>
            <hr />
            <p className="text-lg text-text dark:text-darktext mb-2 mt-3">
              <strong>Follow me on:</strong>
            </p>
            <div className="flex flex-row gap-3">
              <a href="https://t.me/KasraBabazadeh" className="text-darktext hover:scale-125 transition-all duration-300" aria-label="Telegram" target="_blank">
                <FaTelegramPlane className='bg-darkicon p-2 rounded-full' size={40} />
              </a>
              <a href="https://instagram.com/kasra.bbz/" className="text-darktext hover:scale-125 transition-all duration-300" aria-label="Instagram" target="_blank">
                <FaInstagram className='bg-darkicon p-2 rounded-full' size={40} />
              </a>
              <a href="https://linkedin.com/in/kasra-babazadeh" className="text-darktext hover:scale-125 transition-all duration-300" aria-label="LinkedIn" target="_blank">
                <TbBrandLinkedin className='bg-darkicon p-2 rounded-full' size={40} />
              </a>
              <a href="https://github.com/kasrababazadeh" className="text-darktext hover:scale-125 transition-all duration-300" aria-label="GitHub" target="_blank">
                <FaGithub className='bg-darkicon p-2 rounded-full' size={40} />
              </a>
            </div>
          </div>
          {/* Form Grid */}
          <div className='bg-white dark:bg-darkheader shadow-lg rounded-xl px-8 py-6'>
            <h3 className="text-2xl font-bold mb-4 text-text dark:text-darktext">Send a Message</h3>
            <form onSubmit={handleSubmit} className="max-w-md mx-auto">
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 w-full mb-5 group">
                    <input type="text" name="forename" id="forename" value={formData.forename} onChange={handleChange} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-orange-500 focus:outline-none focus:ring-0 focus:border-orange-600 peer" placeholder=" " required />
                    <label htmlFor="forename" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600 peer-focus:dark:text-orange-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First name</label>
                </div>
                <div className="relative z-0 w-full mb-5 group">
                    <input type="text" name="surname" id="surname" value={formData.surname} onChange={handleChange} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-orange-500 focus:outline-none focus:ring-0 focus:border-orange-600 peer" placeholder=" " required />
                    <label htmlFor="surname" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600 peer-focus:dark:text-orange-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Last name</label>
                </div>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                  <input type="email" name="email" id="email" value={formData.email} onChange={handleChange} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-orange-500 focus:outline-none focus:ring-0 focus:border-orange-600 peer" placeholder=" " required />
                  <label htmlFor="email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600 peer-focus:dark:text-orange-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <textarea id="message" name="message" rows="4" value={formData.message} onChange={handleChange} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-orange-500 focus:outline-none focus:ring-0 focus:border-orange-600 peer" placeholder=" " required></textarea>
                <label htmlFor="message" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600 peer-focus:dark:text-orange-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Your message</label>
              </div>
              <div className='grid justify-items-end'>
                <button type="submit" className="text-white bg-darkicon hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-full text-sm w-full sm:w-auto px-12 py-3 text-center dark:focus:ring-orange-800 transition-all duration-300">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactComponent;