import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EducationComponent = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [educations, setEducations] = useState([]);

  useEffect(() => {
    // Fetch education categories on component mount
    axios.get('https://kasrababazadeh.com/api/education-categories/')
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      // Fetch educations when a category is selected
      axios.get(`https://kasrababazadeh.com/api/educations/?category_id=${selectedCategory}`)
        .then(response => {
          console.log(response.data); // Log the response to check the structure
          setEducations(response.data);
        })
        .catch(error => console.error('Error fetching educations:', error));
    }
  }, [selectedCategory]);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  return (
    <section id="education" className="min-h-screen max-w-full px-10 py-24 bg-main dark:bg-darkmain transition-all duration-300 lg:pb-10">
      <div className="container mx-auto">
      {!selectedCategory ? (
        <h2 className="text-4xl font-bold text-center mb-8 text-text dark:text-darktext transition-all duration-300">Education</h2>
        ) : (
          <br className='hidden lg:block' />
          )}
        {!selectedCategory ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-12">
            {categories.map(category => (
              <div
                key={category.id}
                className="relative rounded-lg shadow-lg overflow-hidden transition-all duration-300 cursor-pointer hover:scale-110"
                onClick={() => handleCategoryClick(category.id)}
                style={{ backgroundImage: `url(${category.image})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '500px' }}
              >
                <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
                  <h3 className="text-xl font-semibold text-white">{category.category}</h3>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1">
            {educations.map(exp => (
              <div key={exp.id} className="relative bg-white dark:bg-gray-900 rounded-lg shadow-lg transition-all duration-300 overflow-hidden">
              <img src={exp.banner} alt="banner" className="w-full h-72 object-cover" />
              <div className="relative p-6">
              <div className="absolute -top-28 left-1/2 transform -translate-x-1/2 flex items-center bg-white p-1 rounded shadow-md">
                  <img src={exp.image} alt="icon" className="h-32 w-32" />
                </div>
                <h3 className="text-xl pt-3 font-semibold mb-2 text-text text-center dark:text-darktext transition-all duration-300">
                  {exp.institution}
                </h3>
                <p className="text-text text-center dark:text-darktext mb-1 transition-all duration-300">
                  {exp.degree}
                </p>
                <p className="lg:absolute lg:top-10 lg:left-10 lg:mt-0 mt-3 text-text dark:text-darktext mb-3 transition-all duration-300">
                  Date of Admission: {exp.start_date}
                </p>
                <p className="lg:absolute lg:top-10 lg:right-10 text-text dark:text-darktext mb-3 transition-all duration-300">
                  Date of Graduation: {exp.end_date}
                </p>
                {exp.education_details &&
                  exp.education_details.map((detail) => (
                    <p key={detail.id} className="text-text dark:text-gray-300 text-justify transition-all duration-300">
                      {detail.description}
                    </p>
                  ))}
              </div>
            </div>
            
            ))}
          </div>
        )}
      </div>
    </section>
  );  
};

export default EducationComponent;
