import React, { useState, useEffect } from 'react';
import { FaPlus } from "react-icons/fa";
import axios from 'axios';

const ExperienceComponent = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    // Fetch experience categories on component mount
    axios.get('https://kasrababazadeh.com/api/experience-categories/')
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      // Fetch experiences when a category is selected
      axios.get(`https://kasrababazadeh.com/api/experiences/?category_id=${selectedCategory}`)
        .then(response => {
          console.log(response.data); // Log the response to check the structure
          setExperiences(response.data);
        })
        .catch(error => console.error('Error fetching experiences:', error));
    }
  }, [selectedCategory]);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  return (
    <section id="experience" className="min-h-screen px-10 py-24 bg-main dark:bg-darkmain transition-all duration-300 lg:pb-10">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-8 text-text dark:text-darktext transition-all duration-300">Experience</h2>

        {!selectedCategory ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {categories.map(category => (
              <div
                key={category.id}
                className="relative rounded-lg shadow-lg overflow-hidden transition-all duration-300 cursor-pointer hover:scale-110"
                onClick={() => handleCategoryClick(category.id)}
                style={{ backgroundImage: `url(${category.image})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '500px' }}
              >
                <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
                  <h3 className="text-xl font-semibold text-white">{category.category}</h3>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-8">
            {experiences.map((exp, index) => (
              <React.Fragment key={exp.id}>
                <div
                  className="relative bg-white dark:bg-gray-900 rounded-lg shadow-lg transition-all duration-300 overflow-hidden"
                >
                  <img
                    src={exp.image}
                    alt="banner"
                    className="w-full h-72 object-cover"
                  />
                  <div className="relative p-6">
                    <h3 className="text-xl font-semibold mb-2 text-text text-center dark:text-darktext transition-all duration-300">
                      {exp.company}
                    </h3>
                    <p className="text-text text-center mb-2 dark:text-darktext transition-all duration-300">
                      {exp.position}
                    </p>
                    <p className="lg:absolute lg:top-10 lg:left-10 lg:mt-0 mt-3 text-text dark:text-darktext mb-3 transition-all duration-300">
                      Start Date: {exp.start_date}
                    </p>
                    <p className="lg:absolute lg:top-10 lg:right-10 text-text dark:text-darktext mb-3 transition-all duration-300">
                      End Date: {exp.end_date ? exp.end_date : "Present"}
                    </p>
                    {exp.experience_details &&
                      exp.experience_details.map((detail) => (
                        <p
                          key={detail.id}
                          className="text-text dark:text-gray-300 text-justify transition-all duration-300"
                        >
                          {detail.description}
                        </p>
                      ))}
                  </div>
                </div>
                {/* Conditionally render FaPlus if it's not the last experience */}
                {index < experiences.length - 1 && (
                  <div className="flex justify-center">
                    <div className="h-8 w-8 bg-white dark:bg-darkheader rounded-full flex items-center justify-center shadow-md">
                      <span className="text-text dark:text-darktext"><FaPlus /></span>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </section>
  );  
};

export default ExperienceComponent;
