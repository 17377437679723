import React from 'react';
import { FaLaptopCode, FaBrain } from "react-icons/fa";
import { MdCurrencyBitcoin } from "react-icons/md";
const iconComponents = {
  FaLaptopCode: <FaLaptopCode className="w-16 h-16 p-2 dark:text-darkicon bg-white dark:bg-darkheader rounded-full text-orange-500" />,
  FaBrain: <FaBrain className="w-16 h-16 p-2 dark:text-darkicon bg-white dark:bg-darkheader rounded-full text-orange-500" />,
  MdCurrencyBitcoin: <MdCurrencyBitcoin className="w-16 h-16 p-2 dark:text-darkicon bg-white dark:bg-darkheader rounded-full text-orange-500" />
};

const testimonials = [
  {
    name: 'Web Developer',
    date: 'Since',
    time: '2018',
    feedback: 'Full Stack Web Developer with expertise in both frontend and backend development.',
    icon: 'FaLaptopCode'
  },
  {
    name: 'Data Scientist',
    date: 'Since',
    time: '2021',
    feedback: 'Data Scientist in Machine Learning, Computer Vision, and Deep Learning.',
    icon: 'FaBrain'
  },
  {
    name: 'Technical Trader',
    date: 'Since',
    time: '2021',
    feedback: 'Technical Trader with expertise in Forex and Cryptocurrency trading strategies.',
    icon: 'MdCurrencyBitcoin'
  }
];

const TestimonialCard = ({ name, date, time, feedback, icon }) => (
  <div className="relative bg-white dark:bg-darkheader rounded-lg p-2 shadow-md text-center mt-2">
    <div className="absolute -top-8 left-1/2 transform -translate-x-1/2">
      {iconComponents[icon]}
    </div>
    <h3 className="mt-6 text-lg font-semibold text-text dark:text-darktext">{name}</h3>
    <p className="italic my-4 text-text dark:text-darktext">{feedback}</p>
    <p className="text-gray-500 text-xs dark:text-gray-300">{date} {time}</p>
  </div>
);
const AboutComponent = () => (
  <section id="about" className="min-h-screen px-10 lg:pt-10 lg:pb-0 py-24 bg-main dark:bg-darkmain transition-all duration-300">
    <br className='hidden lg:block' />
    <div className="container mx-auto lg:text-left text-center lg:py-8">
      <div className='justify-center flex mb-4 lg:hidden'>
        <img
            src="profile-mobile.JPG" // Replace with the path to your profile picture
            alt="Profile"
            className="w-52 h-52 object-cover bg-gray-100 rounded-full border-4 border-white dark:border-gray-800 shadow-lg"
          />
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 lg:mb-4'>
        <div className='col-span-2'>
        <h2 className="text-4xl text-text font-bold mb-4 dark:text-darktext transition-all duration-300">About Me</h2>
        <p className="text-lg text-text text-justify dark:text-darktext transition-all duration-300">
        ⁤I'm Kasra Babazadeh, a passionate ull Stack Developer, Data Scientist, and Technical Forex and Cryptocurrency Trader with a Master’s degree in Information Technology. Specializing in dynamic web applications and machine learning models, expertise focuses on innovation, performance optimization, and solving complex challenges. ⁢Proficient in cutting-edge technologies like React, Django, TensorFlow, and Docker, project guidance extends through the entire lifecycle - from conception to deployment. ⁢Committed to continuous learning and improvement, pushing the boundaries of technology and enhancing system efficiency.
        </p>
        <div className="text-center pt-4 lg:pt-8">
          <h2 className="text-2xl font-bold mb-4 dark:text-darktext">What I do</h2>
          <br />
          <div className="grid grid-cols-1 lg:grid-cols-3 justify-center gap-8">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard key={index} {...testimonial} />
            ))}
          </div>
        </div>
        </div>
        <div className="h-full">
          <img
            src="aboutmephoto.png" // Replace with the path to your profile picture
            alt="Profile"
            className="object-contain h-[calc(100vh-13rem)] max-h-full w-full hidden lg:block"
          />
        </div>

      </div>
    </div>
  </section>
);

export default AboutComponent;
