import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HeaderComponent from './components/HeaderComponent';
import AboutComponent from './components/AboutComponent';
import ExperienceComponent from './components/ExperienceComponent';
import EducationComponent from './components/EducationComponent';
import SkillsComponent from './components/SkillsComponent';
import ContactComponent from './components/ContactComponent';
import SidebarComponent from './components/SidebarComponent';
import './index.css';

const WelcomeScreen = ({ isFadingOut }) => {
  return (
    <div
      className={`flex items-center justify-center h-screen transition-opacity duration-1000 ease-in-out ${
        isFadingOut ? 'opacity-0' : 'opacity-100'
      }`}
    >
      <h1 className="text-4xl">Welcome</h1>
    </div>
  );
};

const App = () => {
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(true);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFadingOut(true); // Start fading out the welcome screen
    }, 1000); // Display the welcome screen for 1 second

    const hideTimer = setTimeout(() => {
      setShowWelcomeScreen(false); // Hide the welcome screen after the fade-out
    }, 2000); // Total duration of 2 seconds (1s display + 1s fade out)

    return () => {
      clearTimeout(timer);
      clearTimeout(hideTimer);
    };
  }, []);

  if (showWelcomeScreen) {
    return <WelcomeScreen isFadingOut={isFadingOut} />;
  }

  return (
    <Router>
      <HeaderComponent />
      <div className="flex flex-col lg:flex-row h-screen mx-auto">
        {/* Sidebar */}
        <SidebarComponent />

        {/* Content */}
        <main className="lg:ml-1/6 w-full overflow-auto h-full">
          <Routes>
            <Route exact path="/" element={<AboutComponent />} />
            <Route path="/experience" element={<ExperienceComponent />} />
            <Route path="/education" element={<EducationComponent />} />
            <Route path="/skills" element={<SkillsComponent />} />
            <Route path="/contact" element={<ContactComponent />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
