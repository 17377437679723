import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { HiUser, HiBriefcase, HiAcademicCap, HiLightBulb, HiOutlineMail } from 'react-icons/hi';

const SidebarComponent = () => {
  const location = useLocation();
  const links = [
    { path: '/', label: 'About', icon: <HiUser /> },
    { path: '/experience', label: 'Experience', icon: <HiBriefcase /> },
    { path: '/education', label: 'Education', icon: <HiAcademicCap /> },
    { path: '/skills', label: 'Skills', icon: <HiLightBulb /> },
    { path: '/contact', label: 'Contact', icon: <HiOutlineMail /> },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [majorAxis, setMajorAxis] = useState(250); // Default major axis
  const [minorAxis, setMinorAxis] = useState(80);  // Default minor axis
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const [isDarkMode, setIsDarkMode] = useState(document.documentElement.classList.contains('dark'));

  useEffect(() => {
    const activePath = links.findIndex(link => link.path === location.pathname);
    setActiveIndex(activePath);
  }, [location, links]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const smallScreen = screenWidth <= 1024;
      setIsSmallScreen(smallScreen);

      // Adjust major and minor axis based on screen size
      const newMajorAxis = smallScreen ? screenWidth * 0.4 : screenHeight * 0.30;
      const newMinorAxis = smallScreen ? screenHeight * 0.2 : screenHeight * 0.2;
      setMajorAxis(newMajorAxis);
      setMinorAxis(newMinorAxis);
    };

    handleResize(); // Initial call
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSmallScreen]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setIsDarkMode(document.documentElement.classList.contains('dark'));
    });

    observer.observe(document.documentElement, { attributes: true, attributeFilter: ['class'] });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <aside className={`${isSmallScreen ? 'fixed w-full bottom-0' : 'relative w-52 h-screen'} flex lg:max-h-full items-center justify-center sm:pt-16 md:pt-20 pt-14 lg:pb-0 pb-6 bg-header dark:bg-darkheader lg:bg-main lg:dark:bg-darkmain transition-all duration-300 z-40`}>
      <ul className={`${isSmallScreen ? 'flex-row' : 'flex-col'} w-full h-full flex items-center justify-center list-none p-0 m-0 relative`}>
        {links.map((link, index) => {
          const numLinks = links.length;
          // console.log((activeIndex + numLinks - 1) % numLinks);
          // console.log((activeIndex + numLinks + 1) % numLinks);
          const angleStep = Math.PI / (numLinks - 1); // Angle between each link
          const offsetIndex = (index - activeIndex + Math.floor(numLinks / 2) + numLinks) % numLinks;
          const angle = angleStep * offsetIndex;

          // Use the major and minor axes to position the links
          const radius = majorAxis;
          const adjustedRadius = index === activeIndex ? radius : radius;
          
          // Define x and y coordinates with conditional logic
          let x, y;

          if (index === activeIndex) {
            x = isSmallScreen ? -adjustedRadius * Math.cos(angle) : adjustedRadius * 0.3 * Math.sin(angle);
            y = isSmallScreen ? -adjustedRadius * 0.15 * Math.sin(angle) : -adjustedRadius * Math.cos(angle);
          }
          else if (index === ((activeIndex + numLinks - 1) % numLinks)) {
            x = isSmallScreen ? -adjustedRadius * 0.7 * Math.cos(angle) : adjustedRadius * 0.3 * Math.sin(angle);
            y = isSmallScreen ? -adjustedRadius * 0.15 * Math.sin(angle) : -adjustedRadius * 0.75 * Math.cos(angle);
          }
          else if (index === ((activeIndex + numLinks + 1) % numLinks)) {
            x = isSmallScreen ? -adjustedRadius * 0.7 * Math.cos(angle) : adjustedRadius * 0.3 * Math.sin(angle);
            y = isSmallScreen ? -adjustedRadius * 0.15 * Math.sin(angle) : -adjustedRadius * 0.75 * Math.cos(angle);
          } else {
            x = isSmallScreen ? -adjustedRadius * Math.cos(angle) : adjustedRadius * Math.sin(angle);
            y = isSmallScreen ? -adjustedRadius * Math.sin(angle) : -adjustedRadius * Math.cos(angle);
          }

          

          return (
            <li
              key={link.path}
              className={`lg:me-6 lg:bg-main lg:text-md text-sm lg:dark:bg-darkmain absolute rounded-full flex items-center justify-center transition-all duration-300`}
              style={{
                transform: `translate(${x}px, ${y}px)`,
                zIndex: index === activeIndex ? 1 : 0,
              }}
            >
              <NavLink
                to={link.path}
                className={`text-center lg:text-4xl text-xl flex flex-col items-center justify-center transition-all duration-300 ${index === activeIndex ? 'text-darkicon dark:text-darkicon scale-125' : 'text-text dark:text-darktext hover:scale-110'}`}
                onClick={() => setActiveIndex(index)}
              >
                {link.icon}
                <span className='text-xs'>{link.label}</span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default SidebarComponent;
