import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../index.css'; // Import the stylesheet

const SkillsComponent = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios.get('https://kasrababazadeh.com/api/skill-categories/')
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching skill categories:', error));
  }, []);

  const getProficiencyPercentage = (proficiency) => {
    switch (proficiency) {
      case 'Beg':
        return 25;
      case 'Int':
        return 50;
      case 'Adv':
        return 75;
      case 'Exp':
        return 100;
      default:
        return 0;
    }
  };

  const getFillColor = (percentage) => {
    if (percentage <= 25) return '#7dd3fc'; // Yellow 
    if (percentage <= 50) return '#38bdf8'; // Orange
    if (percentage <= 75) return '#0ea5e9'; // Green
    return '#0284c7'; // Brown
  };

  return (
    <section id="skills" className="min-h-screen px-10 py-24 bg-main dark:bg-darkmain transition-all duration-300 lg:pb-10">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-4 text-text dark:text-darktext transition-all duration-300">Skills</h2>
        {categories.map(category => (
          <div key={category.id} className="mb-4">
            <h3 className="text-2xl font-semibold text-center mb-4 text-text dark:text-darktext transition-all duration-300">{category.title}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {(category.skills || []).map(skill => {
                const percentage = getProficiencyPercentage(skill.proficiency);
                const fillColor = getFillColor(percentage);

                return (
                  <div key={skill.id} className="flex items-center space-x-2 bg-white dark:bg-darkheader p-3 rounded-lg shadow-lg transition-all duration-300">
                    <p className="m-0 p-0 text-md font-semibold text-text dark:text-darktext transition-all duration-300 whitespace-nowrap max-w-full">{skill.name}</p>
                    <div className="skill-bar">
                      <div
                        className="skill-bar-fill"
                        style={{
                          '--target-width': `${percentage}%`,
                          '--start-color': '#e5e7eb',
                          '--end-color': fillColor
                        }}
                      ></div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SkillsComponent;
